/**
 * Polyfills for older browsers (mostly IE11), imported in one place for convenience.
 * This must be included via `<script>` prior to the bundling, or imported directly into the entry point.
 * Please try to use polyfills available from `core-js` to reduce dependencies
 * See also:
 * https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babel
 * https://webpack.js.org/guides/shimming/
 * https://github.com/mfranzke/loading-attribute-polyfill/
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'core-js/modules/web.dom-collections.for-each';
import 'core-js/modules/web.url-search-params';
import 'formdata-polyfill';
import 'loading-attribute-polyfill';

if (!Element.prototype.closest) {
    Element.prototype.closest = function closest(s) {
        let el = this;

        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
}
